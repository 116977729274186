* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  font-family: var(--default-font);
}

body {
  color: var(--on-background-alt);
  background-color: var(--canvas);
}

a {
  color: inherit;
  text-decoration: none;
}
